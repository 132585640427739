@font-face {
  font-family: mono;
  src: url("JetBrainsMono-Regular.2f386850.woff2") format("woff2");
}

@font-face {
  font-family: mono;
  src: url("JetBrainsMono-ThinItalic.f6845198.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: mono-thin;
  src: url("JetBrainsMono-ThinItalic.f6845198.woff2") format("woff2");
  font-style: italic;
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(135deg, #c8c8c5 0%, #a29e8f 100%);
  height: 100vh;
  overflow: hidden;
}

main {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

video {
  object-fit: cover;
  object-position: 50% 50%;
  z-index: 69;
  width: calc(100% + 5rem);
  height: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 1rem));
  }
}

.marquee {
  z-index: 42;
  color: #393838;
  letter-spacing: .8px;
  -webkit-user-select: none;
  user-select: none;
  gap: 1rem;
  font-size: 3rem;
  display: flex;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: 0;
  overflow: hidden;
}

.description {
  text-transform: uppercase;
  font-family: mono-thin;
  font-style: italic;
}

.marquee-content {
  flex-shrink: 0;
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style: none;
  animation: 120s linear infinite scroll;
  display: flex;
}

.marquee-content li {
  margin-left: -1rem;
}

.noise {
  filter: blur(.75px);
  background: url("https://grainy-gradients.vercel.app/noise.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.brand {
  z-index: 420;
  padding: 2.25rem;
  position: absolute;
  top: 0;
  left: 0;
}

.brand a {
  color: #5c5b56;
  text-transform: lowercase;
  font-family: mono;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.brand a:hover {
  color: #717171;
}

.static {
  display: none;
}

@media only screen and (width <= 800px) {
  body {
    background: linear-gradient(170deg, #c8c8c5 0%, #7b786b 100%);
  }

  video {
    top: unset;
    bottom: -25%;
    transform: scale(1.3);
  }

  .brand {
    justify-content: center;
    width: 100%;
    padding: 1.5rem;
    display: flex;
  }

  .brand a {
    font-size: 1.25rem;
  }

  .marquee {
    display: none;
  }

  .description.static {
    font-family: mono;
    font-style: italic;
  }

  .static {
    flex-direction: column;
    gap: .5rem;
    width: 100%;
    font-size: 1.75rem;
    display: flex;
    position: absolute;
    top: 17%;
    left: 0;
  }

  .static span {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .static span:first-child {
    margin-left: -3rem;
  }

  .static span:nth-child(2) {
    margin-left: 1.5rem;
  }
}
/*# sourceMappingURL=index.473b4c43.css.map */
