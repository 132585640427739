@font-face {
  font-family: "mono";
  src: url("../public/JetBrainsMono-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "mono";
  src: url("../public/JetBrainsMono-ThinItalic.woff2") format("woff2");
  font-style: italic;
}
@font-face {
  font-family: "mono-thin";
  src: url("../public/JetBrainsMono-ThinItalic.woff2") format("woff2");
  font-style: italic;
}

*,
::before,
::after {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  border: 0 none;
  font: inherit;
}

body {
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #c8c8c5 0%, #a29e8f 100%);
}

main {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

video {
  object-fit: cover;
  object-position: 50% 50%;
  width: calc(100% + 5rem);
  height: 100%;

  position: relative;
  bottom: 0;
  left: 0;
  z-index: 69;
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 1rem));
  }
}

.marquee {
  position: absolute;
  top: calc(50% - 1.5rem);
  left: 0;
  overflow: hidden;
  z-index: 42;

  color: #393838;
  font-size: 3rem;
  letter-spacing: 0.8px;

  display: flex;
  user-select: none;
  gap: 1rem;
}

.description {
  font-family: "mono-thin";
  font-style: italic;
  text-transform: uppercase;
}
.marquee-content {
  flex-shrink: 0;
  display: flex;
  gap: 1rem;
  animation: scroll 120s linear infinite;
  list-style: none;
  margin: 0;
  padding: 0;
}
.marquee-content li {
  margin-left: -1rem;
}

.noise {
  background: url(https://grainy-gradients.vercel.app/noise.svg);
  filter: blur(0.75px);
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.brand {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2.25rem;
  z-index: 420;
}
.brand a {
  color: #5c5b56;
  text-decoration: none;
  font-size: 1.5rem;
  font-family: "mono";
  font-weight: bold;
  text-transform: lowercase;
  transition: color 0.2s ease-in-out;
}
.brand a:hover {
  color: #717171;
}

.static {
  display: none;
}

@media only screen and (max-width: 800px) {
  body {
    background: linear-gradient(170deg,#c8c8c5 0%,#7b786b 100%);
  }

  video {
    top: unset;
    bottom: -25%;
    transform: scale(1.3);
  }

  .brand {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1.5rem;
  }
  .brand a {
    font-size: 1.25rem;
  }

  .marquee {
    display: none;
  }
  .description.static {
    font-family: "mono";
    font-style: italic;
  }
  .static {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 17%;
    left: 0;
    width: 100%;
    font-size: 1.75rem;
    gap: 0.5rem;
  }
  .static span {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .static span:first-child {
    margin-left: -3rem;
  }
  .static span:nth-child(2) {
    margin-left: 1.5rem;
  }
}
